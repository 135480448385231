import * as React from 'react';

import NavButton from './NavButton';

import { colors } from '../../styles';

interface Props {
  onClick: () => void;
}

const BackButton: React.FC<Props> = ({ onClick }) => (
  <NavButton onClick={onClick} color={colors.black.primary} direction="left" />
);

export default BackButton;
