import * as React from 'react';

import { SvgProps } from '../../types/props';

export const Smiley1: React.FC<SvgProps> = ({ width, height, color }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    width={width}
    height={height}
    fill={color}
  >
    <g>
      <path
        className="st0"
        d="M50,0C22.43,0,0,22.43,0,50c0,27.57,22.43,50,50,50c27.57,0,50-22.43,50-50C100,22.43,77.57,0,50,0z M50,92.98
        C26.3,92.98,7.02,73.7,7.02,50C7.02,26.3,26.3,7.02,50,7.02C73.7,7.02,92.98,26.3,92.98,50C92.98,73.7,73.7,92.98,
        50,92.98z"
      />
      <path
        className="st0"
        d="M50.26,60.7c-7.35,0-14.16,3.99-17.76,10.42c-0.95,1.69-0.35,3.83,1.35,4.78c0.54,0.3,1.13,0.45,1.71,0.45
        c1.23,0,2.42-0.65,3.07-1.79c2.36-4.21,6.82-6.83,11.63-6.83c4.69,0,9.09,2.52,11.48,6.58c0.99,1.67,3.15,2.22,
        4.81,1.24c1.67-0.99,2.22-3.14,1.24-4.81C64.14,64.54,57.43,60.7,50.26,60.7z"
      />
      <path
        className="st0"
        d="M38.91,41.49V38.8c0-1.94-1.57-3.51-3.51-3.51c-1.94,0-3.51,1.57-3.51,3.51v2.68c0,1.56-1.27,2.83-2.83,2.83
        c-1.56,0-2.82-1.27-2.82-2.83V38.8c0-1.94-1.57-3.51-3.51-3.51s-3.51,1.57-3.51,3.51v2.68c0,5.43,4.42,9.85,9.85,
        9.85S38.91,46.92,38.91,41.49z"
      />
      <path
        className="st0"
        d="M78.25,35.29c-1.94,0-3.51,1.57-3.51,3.51v2.68c0,1.56-1.27,2.83-2.82,2.83c-1.56,0-2.83-1.27-2.83-2.83V38.8
        c0-1.94-1.57-3.51-3.51-3.51c-1.94,0-3.51,1.57-3.51,3.51v2.68c0,5.43,4.42,9.85,9.85,9.85s9.85-4.42,9.85-9.85V38.8
        C81.76,36.86,80.19,35.29,78.25,35.29z"
      />
    </g>
  </svg>
);
