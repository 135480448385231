import axios from "axios";
import { right } from "fp-ts/lib/Either";
import { FetchError } from "../../../shared/errors";
import { errorHandler } from "./errorHandler";
import { PromiseE } from "./PromiseE";
import getApiUrl from "../../../shared/getApiUrl";

export type ApiReturn<T, S> = PromiseE<FetchError<T>, Partial<S>>;

export const axiosInstance = axios.create({
  baseURL: getApiUrl(),
});

export const fetch = {
  get: <L, R>(url: string): ApiReturn<L, R> =>
    new PromiseE(
      new Promise((resolve) => {
        axiosInstance
          .get(url)
          .then((res) => resolve(right<FetchError<L>, R>(res.data)))
          .catch((err) => resolve(errorHandler<R, L>(err)));
      })
    ),
  post: <L, R>(url: string, data: any): ApiReturn<L, R> =>
    new PromiseE(
      new Promise((resolve) => {
        axiosInstance
          .post(url, data)
          .then((res) => resolve(right<FetchError<L>, R>(res.data)))
          .catch((err) => resolve(errorHandler<R, L>(err)));
      })
    ),
};
