import { format } from 'date-fns';
import * as React from 'react';
import DatePicker from '../../DatePicker';
import Description from './Description';

import { Date as DateType } from '../../../../api/types/monolith/nuloop';
import { QuestionProps } from '../../../pages/QuestionPage';
import { getConstraintMessage } from '../../../utils';

type Props = QuestionProps & {
  questionType: DateType;
};

const DateInput: React.FC<Props> = ({
  questionType,
  questionType: { constraints: { from = null, to = null } = {} },
  description,
  clientAnswer = [],
  onChange,
}) => {
  return (
    <div>
      <Description
        value={description}
        extra={getConstraintMessage(questionType)}
      />
      <DatePicker
        value={clientAnswer[0] ? new Date(clientAnswer[0]) : undefined}
        onChange={(date) => onChange([format(date, 'YYYY-MM-DD')])}
        range={
          from && to
            ? {
                from: new Date(from).getFullYear(),
                to: new Date(to).getFullYear(),
              }
            : undefined
        }
      />
    </div>
  );
};

export default DateInput;
