import * as React from "react";
import styled from "styled-components";

import Description from "./Description";

import { QuestionProps } from "../../../pages/QuestionPage";
import { colors, fonts } from "../../../styles";

const Textarea = styled.textarea({
  border: "1px solid #313131",
  borderRadius: "5px",
  fontFamily: fonts.HKGrotesk.regular,
  fontSize: "0.9rem",
  padding: "8px",
  width: "100%",
  height: "150px",
  maxWidth: "400px",
  boxSizing: "border-box",
  resize: "none",

  ":focus": {
    outline: "none",
  },
  "::placeholder": {
    color: colors.black.muted,
  },
});

const TextAreaInput: React.FC<QuestionProps> = ({
  description,
  clientAnswer = [],
  onChange,
}) => (
  <div>
    <Description value={description} />
    <Textarea
      autoFocus={true}
      placeholder="Type here..."
      value={clientAnswer[0] || ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange([e.target.value])
      }
    />
  </div>
);

export default TextAreaInput;
