import {
  Answer,
  GetSurveyFormError,
  ProcessResponseError,
} from '../../api/types/monolith/nuloop';
import { SubmitResponseResponse, SurveyForm } from '../../shared/types';
import { fetch } from './utils/fetch';

export const get = (inviteRef: string) =>
  fetch.post<GetSurveyFormError, SurveyForm>(`/getInvite`, {
    inviteRef,
  });

export const submitResponse = (inviteRef: string, answers: Answer[]) =>
  fetch.post<ProcessResponseError, SubmitResponseResponse>(`/submitResponse`, {
    inviteRef,
    answers,
  });
