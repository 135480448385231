import * as queryString from "query-string";
import * as React from "react";

import {
  Answer,
  ProcessResponseError,
  Question,
} from "../api/types/monolith/nuloop";
import { FetchError } from "../shared/errors";
import { SubmitResponseResponse, SurveyForm } from "../shared/types";

import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import * as inviteApi from "./api/inviteApi";
import Header from "./components/Header";
import { Overlay } from "./components/Overlay";
import { questionsOptional, surveyFormOptional } from "./optics/optionals";
import { Finish } from "./pages/Finish";
import { Initial } from "./pages/Initial";
import { PageSpinner } from "./pages/PageSpinnner";
import QuestionPage from "./pages/QuestionPage";
import { ClientAnswer } from "./types/answer";
import { AppState, ClientAnswers, FormErrors } from "./types/state";
import { getErrorMessage } from "./utils/validator";

type Props = RouteComponentProps;

export class InnerApp extends React.Component<Props, AppState> {
  public state: Readonly<AppState> = {
    fetchInvite: {
      error: undefined,
      inFlight: false,
    },
    submitResponse: {
      error: undefined,
      success: undefined,
      inFlight: false,
    },
    surveyForm: undefined,
    clientAnswers: {},
    errors: {},
    onNextTouched: {},
  };

  public static markAllQuestionsTouched(
    surveyForm?: SurveyForm
  ): AppState["onNextTouched"] {
    return surveyForm
      ? Object.keys(surveyForm.questions).reduce((acc, curr) => {
          return {
            ...acc,
            [surveyForm ? surveyForm.questions[curr].id : undefined]: true,
          };
        }, {})
      : {};
  }

  public static questionToPath(
    surveyForm: SurveyForm,
    question: Question
  ): number {
    return surveyForm.questions.indexOf(question) + 1;
  }

  public static caToAnswers(
    surveyForm: SurveyForm,
    clientAnswers: ClientAnswers
  ): Answer[] {
    return surveyForm.questions.reduce((acc: Answer[], question) => {
      const ca = clientAnswers[question.id];
      const answer: Answer = {
        questionId: question.id,
        value:
          question.isRequired &&
          question.questionType &&
          question.questionType.type === "CheckBox" &&
          question.questionType.constraints &&
          question.questionType.constraints.min === 0 &&
          !Array.isArray(clientAnswers[question.id])
            ? []
            : Array.isArray(ca)
            ? ca.map((v) => String(v))
            : undefined,
      };
      return [...acc, answer];
    }, []);
  }

  public static validateClientAnswers(
    surveyForm: SurveyForm | undefined,
    clientAnswers: ClientAnswers
  ): FormErrors {
    if (surveyForm) {
      const formErrors = surveyForm.questions.reduce(
        (acc: FormErrors, question) => {
          return {
            ...acc,
            [question.id]: getErrorMessage(
              question,
              clientAnswers[question.id]
            ),
          };
        },
        {}
      );

      return formErrors;
    }
    return {};
  }

  public static getFirstQuestionWithError(
    surveyForm: SurveyForm | undefined,
    formErrors: FormErrors
  ): Question | null | undefined {
    return Object.keys(formErrors).reduce(
      (acc: Question | null | undefined, questionId) => {
        if (acc) {
          return acc;
        }
        const hasError = !!formErrors[questionId];
        if (hasError) {
          if (surveyForm) {
            const matchedQuestion = surveyForm.questions.find((question) => {
              return String(question.id) === questionId;
            });
            return matchedQuestion;
          }
          return null;
        }
        return null;
      },
      null
    );
  }

  public getInviteRef = (): string | null => {
    const params = queryString.parse(this.props.location.search);
    const inviteRef = params && params.ref;
    return inviteRef && !Array.isArray(inviteRef) ? inviteRef : null;
  };

  public fetchSurveyForm = (): Promise<void> => {
    if (this.state.fetchInvite.inFlight) {
      return Promise.resolve();
    }
    this.setState({ fetchInvite: { inFlight: true } });
    const inviteRef = this.getInviteRef();
    if (inviteRef) {
      return inviteApi.get(inviteRef).fold(
        (err) => {
          this.setState({
            fetchInvite: {
              inFlight: false,
              error: err,
            },
          });
        },
        (surveyForm) => {
          if (surveyForm.questions) {
            this.setState({
              fetchInvite: {
                inFlight: false,
              },
              surveyForm: {
                questions: surveyForm.questions,
                merchant: surveyForm.merchant,
              },
            });
          } else {
            this.setState({
              fetchInvite: {
                inFlight: false,
                error: {
                  type: "EmptyQuestions",
                },
              },
            });
          }
        }
      );
    } else {
      this.setState({
        fetchInvite: {
          inFlight: false,
          error: {
            type: "NoInviteRef",
          },
        },
      });
      return Promise.resolve();
    }
  };
  public componentDidMount() {
    this.fetchSurveyForm();
  }

  public pushRoute = (path: string) => {
    this.props.history.push(path + this.props.location.search);
  };

  public onSubmit = (): Promise<void> => {
    if (this.state.submitResponse.inFlight) {
      return Promise.resolve();
    }
    const { surveyForm, clientAnswers } = this.state;
    const formErrors = InnerApp.validateClientAnswers(
      surveyForm,
      clientAnswers
    );
    this.setState({
      errors: formErrors,
      onNextTouched: InnerApp.markAllQuestionsTouched(this.state.surveyForm),
    });
    const firstQuestionWithError = InnerApp.getFirstQuestionWithError(
      surveyForm,
      formErrors
    );

    if (firstQuestionWithError && this.state.surveyForm) {
      this.pushRoute(
        `/${InnerApp.questionToPath(
          this.state.surveyForm,
          firstQuestionWithError
        )}`
      );
      return Promise.resolve();
    }
    const inviteRef = this.getInviteRef();
    if (inviteRef && this.state.surveyForm) {
      this.setState({ submitResponse: { inFlight: true } });
      return inviteApi
        .submitResponse(
          inviteRef,
          InnerApp.caToAnswers(this.state.surveyForm, this.state.clientAnswers)
        )
        .fold(
          (error: FetchError<ProcessResponseError>) => {
            this.setState({
              submitResponse: {
                error,
                inFlight: false,
              },
            });
          },
          (success: Partial<SubmitResponseResponse>) => {
            this.setState({
              submitResponse: {
                success,
                inFlight: false,
              },
            });
          }
        )
        .then(() => this.pushRoute("/finish"));
    } else {
      this.setState({
        submitResponse: {
          inFlight: false,
          error: {
            type: "NoInviteRef",
          },
        },
      });
      return Promise.resolve();
    }
  };

  public onAnswerChange = (question: Question, value: ClientAnswer) => {
    this.setState((prevState) => ({
      clientAnswers: {
        ...prevState.clientAnswers,
        [question.id]: value,
      },
      errors: {
        ...prevState.errors,
        [question.id]: getErrorMessage(question, value),
      },
    }));
  };

  public onNext = (
    question: Question,
    answer: ClientAnswer,
    currentRoute: number
  ) => {
    this.setState(
      (prevState) => ({
        clientAnswers: {
          ...prevState.clientAnswers,
          [question.id]:
            prevState.clientAnswers[question.id] || answer
              ? prevState.clientAnswers[question.id]
              : [],
        },
        errors: {
          ...prevState.errors,
          [question.id]: getErrorMessage(question, answer),
        },
        onNextTouched: {
          ...prevState.onNextTouched,
          [question.id]: true,
        },
      }),
      () => {
        if (!this.state.errors[question.id]) {
          this.pushRoute(`/${currentRoute + 1}`);
        }
      }
    );
  };

  public onStart = () => {
    if (this.state.surveyForm && this.state.surveyForm.questions) {
      this.pushRoute(
        String(
          InnerApp.questionToPath(
            this.state.surveyForm,
            this.state.surveyForm.questions[0]
          )
        )
      );
    }
  };

  public renderQuestionRoutes(): React.ReactNode {
    const whenSome = (surveyForm: SurveyForm): React.ReactElement => {
      return (
        <>
          {surveyForm.questions.map((question, i) => {
            const path = InnerApp.questionToPath(surveyForm, question);
            const clientAnswer = this.state.clientAnswers[question.id];
            const error = this.state.errors[question.id];
            const onNextTouched = this.state.onNextTouched[question.id];
            const isLast = i + 1 === surveyForm.questions.length;
            return (
              <Route
                key={question.id}
                path={`/${path}`}
                exact={true}
                render={() => (
                  <QuestionPage
                    {...question}
                    sequence={path}
                    clientAnswer={clientAnswer}
                    error={error}
                    onNextTouched={onNextTouched}
                    onChange={(value) => this.onAnswerChange(question, value)}
                    onNext={() => this.onNext(question, clientAnswer, path)}
                    onBack={this.props.history.goBack}
                    onSubmit={isLast ? this.onSubmit : undefined}
                  />
                )}
              />
            );
          })}
        </>
      );
    };

    return surveyFormOptional
      .getOption(this.state)
      .fold(<Redirect to={`/${this.props.location.search}`} />, whenSome);
  }

  public render() {
    if (this.state.fetchInvite.inFlight) {
      return <PageSpinner />;
    }
    return (
      <React.Fragment>
        {this.state.submitResponse.inFlight && <Overlay />}
        <Header />
        <Switch>
          <Route
            path="/"
            exact={true}
            render={() => (
              <Initial
                surveyForm={this.state.surveyForm}
                fetchInvite={this.state.fetchInvite}
                onStart={this.onStart}
              />
            )}
          />
          {questionsOptional.getOption(this.state).fold(null, () => (
            <React.Fragment>
              {this.renderQuestionRoutes()}
              <Route
                path="/finish"
                exact={true}
                render={() => (
                  <Finish
                    submitResponse={this.state.submitResponse}
                    resubmit={this.onSubmit}
                  />
                )}
              />
            </React.Fragment>
          ))}
          <Redirect to={`/${this.props.location.search}`} />
        </Switch>
      </React.Fragment>
    );
  }
}

export const App = withRouter(InnerApp);
