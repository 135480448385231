import * as React from "react";
import { ErrorFullPageDisplay } from "./FullPageDisplay";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError?: boolean;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: any, info: any) {
    console.log(error, info);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorFullPageDisplay />;
    }

    return this.props.children;
  }
}
