import * as React from 'react';
import styled from 'styled-components';
import { fonts } from '../../styles';

const Container = styled.div({
  maxWidth: '900px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '-10vh auto 0 auto',
  padding: '0 1rem',
  justifyContent: 'center',
  minHeight: '100vh'
});

const Logo = styled.img({
  maxHeight: '176px',
  marginBottom: '1rem'
});
const MessageContainer = styled.div({
  margin: '0 0 2.5rem 0'
});
const Message = styled.p({
  fontFamily: fonts.HKGrotesk.bold,
  fontSize: '1.5rem',
  margin: 0,
  textAlign: 'center'
});

const Submessage = styled.p({
  fontFamily: fonts.HKGrotesk.regular,
  fontSize: '1rem',
  margin: ' 0.5rem 0 0 0',
  textAlign: 'center'
});

export interface Props {
  imageSrc?: string;
  message?: React.ReactNode;
  subMessage?: React.ReactNode;
  callToAction?: React.ReactNode;
}

export const FullPageDisplay: React.FC<Props> = ({
  imageSrc,
  message,
  callToAction,
  subMessage
}) => (
  <Container>
    <Logo
      src={
        imageSrc
          ? imageSrc
          : require('../../assets/images/unkown_logo-256x200@2x.png')
      }
    />
    <MessageContainer>
      {message && <Message>{message}</Message>}
      {subMessage && <Submessage>{subMessage}</Submessage>}
    </MessageContainer>
    {callToAction}
  </Container>
);
