import * as React from 'react';
import { ErrorFullPageDisplay } from '../../FullPageDisplay';
import { Props } from '../QuestionInput';

export class NoMatchInput extends React.Component<Props, {}> {
  public componentDidMount() {
    console.log(this.props.id, this.props.questionType);
  }
  public render() {
    return <ErrorFullPageDisplay />;
  }
}
