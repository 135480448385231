import { Optional } from 'monocle-ts';
import { SurveyForm } from '../../shared/types';
import { AppState } from '../types/state';

export const surveyFormOptional = Optional.fromNullableProp<AppState>()(
  'surveyForm'
);
export const merchantOptional = Optional.fromNullableProp<SurveyForm>()(
  'merchant'
);

export const questionsOptional = surveyFormOptional.compose(
  Optional.fromNullableProp<SurveyForm>()('questions')
);
