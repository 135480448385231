import { isEqual } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';

export class SanitizeQuery extends React.Component<any> {
  public static captureEndNonAlphaNum = /[^a-zA-Z\d\s]+$/;
  public static sanitizeQueryParams(search: string): { [key: string]: string } {
    return Object.entries(queryString.parse(search)).reduce((acc, curr) => {
      if (curr[0]) {
        if (typeof curr[1] === 'string') {
          acc[curr[0]] = curr[1].replace(
            SanitizeQuery.captureEndNonAlphaNum,
            '',
          );
        }
        return acc;
      }
      return acc;
    }, {});
  }
  public static hasUnsanitizedQueryParams(search: string): boolean {
    return Object.entries(queryString.parse(search)).reduce((acc: boolean, curr) => {
      if (typeof curr[1] === 'string') {
        return RegExp(SanitizeQuery.captureEndNonAlphaNum).test(curr[1]) || acc;
      }
      return acc;
    }, false);
  }
  public redirect() {
    const { search } = document.location;
    const sanitizedParams = SanitizeQuery.sanitizeQueryParams(search);
    if (!isEqual(sanitizedParams, queryString.parse(search))) {
      document.location.href =
        document.location.pathname +
        '?' +
        queryString.stringify(sanitizedParams);
    }
  }
  constructor(props: any) {
    super(props);
    this.redirect();
  }
  public render() {
    return SanitizeQuery.hasUnsanitizedQueryParams(document.location.search)
      ? null
      : this.props.children;
  }
}
