import * as React from 'react';

import { SvgProps } from '../../types/props';

export const BlankStar: React.FC<SvgProps> = ({ width, height, color }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 104.53 100"
    width={width}
    height={height}
    fill={color}
  >
    <g>
      <path
        className="st0"
        /* tslint:disable-next-line */
        d="M52.27,5.98l14.3,28.98l31.98,4.65L75.41,62.17l5.46,31.85L52.27,78.98L23.66,94.02l5.46-31.85L5.98,39.61l31.98-4.65L52.27,5.98 M52.27,0c-2.28,0-4.36,1.29-5.36,3.33L33.99,29.49l-28.87,4.2C2.87,34.02,1,35.59,0.29,37.76c-0.7,2.17-0.12,4.54,1.51,6.13L22.7,64.25l-4.93,28.75c-0.38,2.24,0.54,4.51,2.38,5.85c1.04,0.76,2.28,1.14,3.52,1.14c0.95,0,1.91-0.23,2.78-0.69l25.82-13.58l25.82,13.58c0.88,0.46,1.83,0.69,2.78,0.69c1.24,0,2.47-0.39,3.52-1.14c1.84-1.34,2.76-3.61,2.38-5.85l-4.93-28.75l20.89-20.36c1.63-1.59,2.22-3.97,1.51-6.13c-0.7-2.17-2.58-3.74-4.83-4.07l-28.87-4.2L57.63,3.33C56.62,1.29,54.54,0,52.27,0L52.27,0z"
      />
    </g>
  </svg>
);
