import { getConstraintMessage } from '../utils';

import { Question, QuestionType } from '../../api/types/monolith/nuloop';
import { ClientAnswer } from '../types/answer';
import { ErrorMsg } from '../types/error';

type ValidatorFunc = (answer: ClientAnswer) => ErrorMsg;

const validator = (questionType: QuestionType): ValidatorFunc => {
  switch (questionType.type) {
    case 'CheckBox':
      return (answer: ClientAnswer) => {
        const { min = 0, max = questionType.selection.length } =
          questionType.constraints || {};
        return answer && (answer.length < min || answer.length > max)
          ? getConstraintMessage(questionType)
          : undefined;
      };
    case 'Date':
      return (answer: ClientAnswer) => {
        const { from = null, to = null } = questionType.constraints || {};
        if (!(from && to)) {
          return undefined;
        }
        return answer && (answer[0] < from || answer[0] > to)
          ? getConstraintMessage(questionType)
          : undefined;
      };
    default:
      return () => undefined;
  }
};

const hasAnswer = (question: Question, answer: ClientAnswer): boolean =>
  question.questionType.type === 'CheckBox' &&
  question.questionType.constraints &&
  question.questionType.constraints.min === 0
    ? true
    : answer
    ? answer.length > 0 && answer[0].toString().trim() !== ''
    : false;
export const getErrorMessage = (
  question: Question,
  answer: ClientAnswer
): ErrorMsg => {
  if (hasAnswer(question, answer)) {
    return validator(question.questionType)(answer);
  } else {
    return question.isRequired
      ? 'An answer for this question is required'
      : undefined;
  }
};
