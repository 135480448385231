import * as React from 'react';
import styled from 'styled-components';

interface Props {
  min?: string;
  max?: string;
}

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '0.6rem',
  marginBottom: '5px',
});

const RatingLabels: React.FC<Props> = ({ min, max }) => (
  <Container>
    <span>{min}</span>
    <span>{max}</span>
  </Container>
);

export default RatingLabels;
