import * as React from 'react';

import CheckBoxInput from './QuestionInputs/CheckBoxInput';
import DateInput from './QuestionInputs/DateInput';
import DropDownInput from './QuestionInputs/DropDownInput';
import RadioInput from './QuestionInputs/RadioInput';
import RatingNumberInput from './QuestionInputs/RatingNumberInput';
import RatingSmileyInput from './QuestionInputs/RatingSmileyInput';
import RatingStarInput from './QuestionInputs/RatingStarInput';
import TextAreaInput from './QuestionInputs/TextAreaInput';
import TextFieldInput from './QuestionInputs/TextFieldInput';

import { Question } from '../../../api/types/monolith/nuloop';
import { QuestionProps } from '../../pages/QuestionPage';
import { NoMatchInput } from './QuestionInputs/NoMatchInput';

export type Props = Pick<Question, 'id' | 'questionType'> & QuestionProps;

const QuestionInput: React.FC<Props> = ({
  id,
  questionType,
  description,
  clientAnswer,
  onChange,
}) => (
  <div>
    {(() => {
      const commonProps = { description, clientAnswer, onChange };
      switch (questionType.type) {
        case 'TextField':
          return <TextFieldInput {...commonProps} />;
        case 'TextArea':
          return <TextAreaInput {...commonProps} />;
        case 'RatingNumber':
          return (
            <RatingNumberInput {...commonProps} questionType={questionType} />
          );
        case 'Radio':
          return <RadioInput {...commonProps} questionType={questionType} />;
        case 'RatingSmiley':
          return (
            <RatingSmileyInput {...commonProps} questionType={questionType} />
          );
        case 'RatingStar':
          return (
            <RatingStarInput {...commonProps} questionType={questionType} />
          );
        case 'CheckBox':
          return <CheckBoxInput {...commonProps} questionType={questionType} />;
        case 'DropDown':
          return <DropDownInput {...commonProps} questionType={questionType} />;
        case 'Date':
          return <DateInput {...commonProps} questionType={questionType} />;
        default:
          return (
            <NoMatchInput
              {...commonProps}
              questionType={questionType}
              id={id}
            />
          );
      }
    })()}
  </div>
);

export default QuestionInput;
