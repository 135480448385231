import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { SanitizeQuery } from "./SanitizeQuery";

import "./index.css";

ReactDOM.render(
  <ErrorBoundary>
    <SanitizeQuery>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SanitizeQuery>
  </ErrorBoundary>,
  document.getElementById("root")! as HTMLElement
);

if (import.meta.hot) {
  import.meta.hot.accept();
}
