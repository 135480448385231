import * as React from 'react';
import styled from 'styled-components';

import { BlankStar, SolidStar } from '../../Stars';
import RatingLabels from './RatingLabels';
import withRating from './withRating';

import { QuestionProps } from '../../../pages/QuestionPage';
import { colors } from '../../../styles';

interface Props extends QuestionProps {
  min?: string;
  max?: string;
  selection: number[];
}

const Container = styled.div({
  display: 'inline-block',
  marginBottom: '15px'
});

const StarWrapper = styled.button({
  border: 'none',
  backgroundColor: colors.white,
  cursor: 'pointer',
  padding: '4px',
  margin: '2px',

  ':focus': {
    outline: 'none'
  }
});

export const RatingStarInput: React.FC<Props> = ({
  min,
  max,
  selection,
  clientAnswer = [],
  onChange
}) => (
  <Container>
    <RatingLabels min={min} max={max} />
    {selection.map((value) => (
      <StarWrapper key={value} onClick={() => onChange([value])}>
        {value <= clientAnswer[0] ? (
          <SolidStar width="36px" height="36px" color={colors.red.primary} />
        ) : (
          <BlankStar width="36px" height="36px" color={colors.black.muted} />
        )}
      </StarWrapper>
    ))}
  </Container>
);

export default withRating(RatingStarInput);
