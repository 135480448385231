import * as React from 'react';

import { SvgProps } from '../../types/props';

export const Smiley5: React.FC<SvgProps> = ({ width, height, color }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    width={width}
    height={height}
    fill={color}
  >
    <g>
      <path
        className="st0"
        d="M50,0C22.43,0,0,22.43,0,50c0,27.57,22.43,50,50,50c27.57,0,50-22.43,50-50C100,22.43,77.57,0,50,0z M50,92.98
        C26.3,92.98,7.02,73.7,7.02,50C7.02,26.3,26.3,7.02,50,7.02C73.7,7.02,92.98,26.3,92.98,50C92.98,73.7,73.7,92.98
        ,50,92.98z"
      />
      <path
        className="st0"
        d="M34.83,43.31c0-3.19-2.59-5.78-5.78-5.78s-5.78,2.59-5.78,5.78c0,3.19,2.59,5.78,5.78,5.78
        S34.83,46.5,34.83,43.31z"
      />
      <circle className="st0" cx="71.91" cy="43.31" r="5.78" />
      <path
        className="st0"
        /* tslint:disable-next-line */
        d="M65.44,60.7H34.56c-1.94,0-3.51,1.57-3.51,3.51c0,10.45,8.5,18.95,18.95,18.95c10.45,0,18.95-8.5,18.95-18.95C68.95,62.27,67.38,60.7,65.44,60.7z M50,76.13c-5.35,0-9.89-3.55-11.4-8.41H61.4C59.89,72.59,55.35,76.13,50,76.13z"
      />
    </g>
  </svg>
);
