import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div({
  backgroundColor: '#bb3324',
  paddingTop: '18px',
  paddingBottom: '18px',
  textAlign: 'center',
  position: 'sticky',
  top: 0
});

const Logo = styled.img({
  maxWidth: '50px',
  maxHeight: '50px'
});

const Header: React.FC = () => {
  return (
    <Wrapper>
      <Logo src={require('../assets/images/zap.svg')} alt="" />
    </Wrapper>
  );
};

export default Header;
