import { fromNullable } from 'fp-ts/lib/Option';
import * as React from 'react';
import { MerchantBasicDetails, SurveyForm } from '../../shared/types';
import { PrimaryButton } from '../components/Button/PrimaryButton';
import {
  ErrorFullPageDisplay,
  FullPageDisplay
} from '../components/FullPageDisplay';
import { merchantOptional } from '../optics/optionals';
import { AppState } from '../types/state';

type Props = Pick<AppState, 'surveyForm' | 'fetchInvite'> & {
  onStart: () => void;
};

const GenericErrorPage: React.FC = () => (
  <ErrorFullPageDisplay subMessage={'Please refresh the page and try again.'} />
);

export const Initial: React.FC<Props> = ({
  fetchInvite,
  surveyForm: surveyFormNullable,
  onStart
}) => {
  if (fetchInvite.error) {
    switch (fetchInvite.error.type) {
      case 'DisjunctionError':
        const { error: disErr } = fetchInvite;
        switch (disErr.error.type) {
          case 'AlreadyAnswered': {
            const { merchant = {} } = { ...disErr.error };
            return (
              <FullPageDisplay
                imageSrc={merchant.logo}
                message={'You have already answered this survey.'}
              />
            );
          }
          case 'Expired': {
            const { merchant = {} } = { ...disErr.error };
            return (
              <FullPageDisplay
                imageSrc={merchant.logo}
                message={'The link to this survey has already expired.'}
              />
            );
          }
          case 'NotFound':
            return (
              <FullPageDisplay
                imageSrc={require('../assets/images/unkown_error-321x220@2x.png')}
                message={'The survey cannot be found.'}
              />
            );
          case 'OtherError':
          default:
            return <GenericErrorPage />;
        }
      default:
        return <GenericErrorPage />;
    }
  }
  return fromNullable(surveyFormNullable).fold(
    <GenericErrorPage />,
    (surveyForm: SurveyForm) => {
      const { name, logo }: MerchantBasicDetails = merchantOptional
        .getOption(surveyForm)
        .fold({}, (m) => m);
      return (
        <FullPageDisplay
          imageSrc={logo}
          message={
            <span>
              Share your experience
              {name ? (
                <React.Fragment>
                  <br />
                  at {name}
                </React.Fragment>
              ) : null}
            </span>
          }
          callToAction={
            <PrimaryButton onClick={onStart} type="button">
              Start Now
            </PrimaryButton>}
        />
      );
    }
  );
};
