import * as React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../styles';
interface Props {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick: () => void;
}

const Button = styled.button({
  border: 'none',
  backgroundColor: colors.red.primary,
  color: colors.white,
  padding: '0.7rem 2rem',
  fontSize: '1.1rem',
  fontFamily: fonts.HKGrotesk.bold,
  letterSpacing: '0.3px',
  borderRadius: '3px'
});

export const PrimaryButton: React.FC<Props> = ({
  children,
  type,
  onClick,
  className
}) => (
  <Button className={className} onClick={onClick} type={type}>
    {children}
  </Button>
);
