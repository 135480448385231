import * as React from 'react';

import DropDown from '../../DropDown';
import Description from './Description';

import { DropDown as DropDownType } from '../../../../api/types/monolith/nuloop';
import { QuestionProps } from '../../../pages/QuestionPage';

type Props = QuestionProps & {
  questionType: DropDownType;
};

const DropDownInput: React.FC<Props> = ({
  questionType: { selection },
  description,
  clientAnswer = [],
  onChange,
}) => (
  <div>
    <Description value={description} />
    <DropDown
      title="Select..."
      defaultValue="Select..."
      selection={selection}
      value={clientAnswer[0]}
      onChange={(selected) => onChange([selected])}
    />
  </div>
);

export default DropDownInput;
