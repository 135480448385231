const colors = {
  red: {
    primary: '#BD311F',
    secondary: '#D5837C'
  },
  black: {
    primary: '#313131',
    secondary: '#686868',
    muted: '#BABBBE'
  },
  white: '#FFFFFF'
};

export default colors;
