import * as React from 'react';

import { SvgProps } from '../../types/props';

export const Smiley4: React.FC<SvgProps> = ({ width, height, color }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    width={width}
    height={height}
    fill={color}
  >
    <g>
      <path
        className="st0"
        d="M50,0C22.43,0,0,22.43,0,50c0,27.57,22.43,50,50,50c27.57,0,50-22.43,50-50C100,22.43,77.57,0,50,0z M50,92.98
        C26.3,92.98,7.02,73.7,7.02,50C7.02,26.3,26.3,7.02,50,7.02C73.7,7.02,92.98,26.3,92.98,50C92.98,73.7,73.7,92.98,
        50,92.98z"
      />
      <path
        className="st0"
        d="M66.56,61.5c-1.66-0.98-3.82-0.43-4.81,1.24c-2.39,4.06-6.8,6.58-11.48,6.58c-4.81,0-9.27-2.62-11.63-6.83
        c-0.95-1.7-3.1-2.29-4.78-1.35c-1.69,0.95-2.3,3.09-1.35,4.78c3.61,6.43,10.41,10.42,17.76,10.42c7.16,0,13.88
        -3.84,17.53-10.03
        C68.78,64.64,68.23,62.49,66.56,61.5z"
      />
      <circle className="st0" cx="29.06" cy="43.31" r="5.78" />
      <circle className="st0" cx="71.91" cy="43.31" r="5.78" />
    </g>
  </svg>
);
