import * as React from 'react';
import styled from 'styled-components';

import DropDown from './DropDown';

interface Props {
  value?: Date;
  range?: {
    from: number;
    to: number;
  };
  onChange: (value: Date) => void;
}

interface State {
  month?: string;
  day?: string;
  year?: string;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const getDays = (acc: string[], n: number): string[] => {
  if (n > 0) {
    return getDays([n.toString(), ...acc], n - 1);
  } else {
    return acc;
  }
};

const days = getDays([], 31);

const getYears = (acc: string[], n: number, length: number): string[] => {
  if (acc.length < length) {
    return getYears([...acc, n.toString()], n - 1, length);
  } else {
    return acc;
  }
};

const years: string[] = getYears([], new Date().getFullYear(), 150);

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  gridColumnGap: '10px',
  justifyContent: 'center'
});

class DatePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      month: props.value && months[props.value.getMonth()],
      day: props.value && props.value.getDate().toString(),
      year: props.value && props.value.getFullYear().toString()
    };
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.month !== this.state.month ||
      prevState.day !== this.state.day ||
      prevState.year !== this.state.year
    ) {
      const { month, day, year } = this.state;
      if (month && day && year) {
        this.props.onChange(new Date(`${month} ${day}, ${year}`));
      }
    }
  }

  public render() {
    const { value, range } = this.props;
    return (
      <Container>
        <DropDown
          title="MM"
          defaultValue="MM"
          selection={months}
          value={value ? months[value.getMonth()] : this.state.month}
          onChange={(month) => this.setState({ month })}
        />
        <DropDown
          title="DD"
          defaultValue="DD"
          selection={days}
          value={value ? value.getDate().toString() : this.state.day}
          onChange={(day) => this.setState({ day })}
        />
        <DropDown
          title="YYYY"
          defaultValue="YYYY"
          selection={
            range ? getYears([], range.to, range.to - range.from + 1) : years
          }
          value={value ? value.getFullYear().toString() : this.state.year}
          onChange={(year) => this.setState({ year })}
        />
      </Container>
    );
  }
}

export default DatePicker;
