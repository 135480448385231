import { format } from 'date-fns';

import { QuestionType } from '../../api/types/monolith/nuloop';

const formatDate = (date: number) => format(new Date(date), 'MMM DD, YYYY');

export const getConstraintMessage = (questionType: QuestionType) => {
  switch (questionType.type) {
    case 'CheckBox': {
      const {
        selection,
        constraints: { min = 0, max = questionType.selection.length } = {},
      } = questionType;
      if (min === max) {
        if (min === 0) {
          return undefined;
        }
        return `Select ${min} ${min === 1 ? 'choice' : 'choices'}`;
      }
      if (min === 0) {
        if (max === selection.length) {
          return undefined;
        } else if (max < selection.length) {
          return `Select at most ${max} choices`;
        }
      } else if (min > 0) {
        if (max === selection.length) {
          return `Select at least ${min} ${min === 1 ? 'choice' : 'choices'}`;
        } else if (max < selection.length) {
          return `Select ${min}-${max} choices`;
        }
      }
      return undefined;
    }
    case 'Date': {
      const { constraints: { from = null, to = null } = {} } = questionType;
      return from && to
        ? `Select from ${formatDate(from)} to ${formatDate(to)}`
        : undefined;
    }
    default: {
      return undefined;
    }
  }
};
