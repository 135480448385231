import * as React from 'react';
import { SvgProps } from '../../types/props';

export const SolidStar: React.FC<SvgProps> = ({ width, height, color }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 104.53 100"
    width={width}
    height={height}
    fill={color}
  >
    <path
      className="st0"
      /* tslint:disable-next-line */
      d="M104.24,37.76c-0.7-2.17-2.58-3.74-4.83-4.07l-28.87-4.19L57.63,3.33C56.62,1.29,54.54,0,52.27,0s-4.36,1.29-5.36,3.33L33.99,29.49L5.12,33.69C2.87,34.02,1,35.59,0.29,37.76s-0.12,4.54,1.51,6.13L22.7,64.25l-4.93,28.75c-0.38,2.24,0.54,4.51,2.38,5.85c1.04,0.76,2.28,1.14,3.52,1.14c0.95,0,1.91-0.23,2.78-0.69l25.82-13.58l25.82,13.58c0.88,0.46,1.83,0.69,2.78,0.69c1.24,0,2.47-0.39,3.52-1.14c1.84-1.34,2.76-3.61,2.38-5.85l-4.93-28.75l20.89-20.36C104.35,42.3,104.94,39.93,104.24,37.76z"
    />
  </svg>
);
