interface Media {
  [key: string]: string;
}

const sizes = {
  tablet: 768
};

const media: Media = Object.keys(sizes).reduce((acc, curr) => {
  return {
    ...acc,
    [curr]: `@media(min-width: ${sizes[curr]}px)`
  };
},                                             {});

export default media;
