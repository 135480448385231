import * as React from 'react';
import styled from 'styled-components';

import Description from './Description';

import { CheckBox } from '../../../../api/types/monolith/nuloop';
import { QuestionProps } from '../../../pages/QuestionPage';
import { colors, fonts, media } from '../../../styles';
import { getConstraintMessage } from '../../../utils';

interface BoxProps {
  selected: boolean;
  disabled: boolean;
}
interface CheckBoxValueProps {
  disabled: boolean;
}

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gridColumnGap: '10px',
  justifyContent: 'center',
  justifyItems: 'start',

  [media.tablet]: {
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: '40px',
  },
});

const CheckBoxContainer = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  backgroundColor: colors.white,
  fontFamily: fonts.HKGrotesk.regular,
  fontSize: '1rem',
  color: colors.black.primary,
  cursor: 'pointer',
  marginBottom: '8px',

  ':focus': {
    outline: 'none',
  },
});

const Box = styled.div<BoxProps>((props) => ({
  backgroundColor: props.selected ? colors.red.primary : colors.white,
  borderColor: props.disabled ? colors.black.muted : colors.black.primary,
  borderWidth: '1px',
  borderStyle: 'solid',
  width: '15px',
  height: '15px',
  display: 'inline-block',
  marginRight: '10px',

  ...(props.selected && {
    backgroundImage: `url(${require('../../../assets/icons/check.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '10px',
    backgroundPosition: 'center center',
  }),
}));

const CheckBoxValue = styled.div<CheckBoxValueProps>((props) => ({
  color: props.disabled ? colors.black.muted : colors.black.primary,
  display: 'inline-block',
}));

type Props = QuestionProps & {
  questionType: CheckBox;
};

const CheckBoxInput: React.FC<Props> = ({
  questionType,
  questionType: { selection, constraints: { max = selection.length } = {} },
  description,
  clientAnswer = [],
  onChange,
}) => {
  return (
    <div>
      <Description
        value={description}
        extra={getConstraintMessage(questionType)}
      />
      <Container>
        {selection.map((value) => (
          <CheckBoxContainer
            key={value}
            onClick={() =>
              onChange(
                !clientAnswer.includes(value) && clientAnswer.length < max
                  ? [...clientAnswer, value]
                  : clientAnswer.filter((v) => v !== value)
              )
            }
          >
            <Box
              selected={clientAnswer.includes(value)}
              disabled={
                clientAnswer.length >= max && !clientAnswer.includes(value)
              }
            />
            <CheckBoxValue
              disabled={
                clientAnswer.length >= max && !clientAnswer.includes(value)
              }
            >
              {value}
            </CheckBoxValue>
          </CheckBoxContainer>
        ))}
      </Container>
    </div>
  );
};

export default CheckBoxInput;
