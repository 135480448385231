import { AxiosError } from 'axios';
import { Either, left } from 'fp-ts/lib/Either';
import { FetchError, UnknownError } from '../../../shared/errors';

export const errorHandler = <A, B = any>(
  err: any
): Either<FetchError<B>, A> => {
  const error: AxiosError = { ...err };
  if (error.response) {
    return left(error.response.data);
  } else {
    const unknownErr: UnknownError = {
      type: 'UnknownError',
      error
    };
    return left(unknownErr);
  }
};
