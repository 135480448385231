import * as React from 'react';
import styled from 'styled-components';

import { Smiley1, Smiley2, Smiley3, Smiley4, Smiley5 } from '../../Smileys';
import RatingLabels from './RatingLabels';
import withRating from './withRating';

import { QuestionProps } from '../../../pages/QuestionPage';
import { colors } from '../../../styles';

const smileys = (length: number) => {
  switch (length) {
    case 3:
      return {
        1: Smiley1,
        2: Smiley3,
        3: Smiley5
      };
    case 5:
      return {
        1: Smiley1,
        2: Smiley2,
        3: Smiley3,
        4: Smiley4,
        5: Smiley5
      };
    default:
      return {};
  }
};

interface Props extends QuestionProps {
  min?: string;
  max?: string;
  selection: number[];
}

const Container = styled.div({
  display: 'inline-block'
});

const SmileyWrapper = styled.button({
  border: 'none',
  backgroundColor: colors.white,
  cursor: 'pointer',
  padding: '4xp',
  margin: '2px',

  ':focus': {
    outline: 'none'
  }
});

export const RatingSmileyInput: React.FC<Props> = ({
  min,
  max,
  selection,
  clientAnswer = [],
  onChange
}) => (
  <Container>
    <RatingLabels min={min} max={max} />
    {selection.map((value) => {
      const Smiley = smileys(selection.length)[value];
      return (
        <SmileyWrapper key={value} onClick={() => onChange([value])}>
          {Smiley ? (
            <Smiley
              width="32px"
              height="32px"
              color={
                clientAnswer[0] === value
                  ? colors.red.primary
                  : colors.black.muted
              }
            />
          ) : null}
        </SmileyWrapper>
      );
    })}
  </Container>
);

export default withRating(RatingSmileyInput);
