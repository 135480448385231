import * as React from 'react';

import NavButton from './NavButton';

import { colors } from '../../styles';

interface Props {
  onClick: () => void;
}

const NextButton: React.FC<Props> = ({ onClick }) => (
  <NavButton onClick={onClick} color={colors.red.primary} direction="right" />
);

export default NextButton;
