import * as React from 'react';
import styled from 'styled-components';

import { PrimaryButton } from '../components/Button/PrimaryButton';
import ErrorMessage from '../components/ErrorMessage';
import NavButtons from '../components/NavButtons';
import QuestionInput from '../components/Question/QuestionInput';

import { Question } from '../../api/types/monolith/nuloop';
import { fonts, media } from '../styles';
import { ClientAnswer } from '../types/answer';
import { ErrorMsg } from '../types/error';

export type QuestionProps = Pick<Question, 'description'> & {
  clientAnswer: ClientAnswer;
  onChange: (value: ClientAnswer) => void;
};

export type Props = QuestionProps &
  Pick<Question, 'id' | 'value' | 'questionType' | 'isRequired'> & {
    sequence: number;
    error: ErrorMsg;
    onNext: () => void;
    onBack: () => void;
    onSubmit?: () => void;
    onNextTouched: boolean;
  };

const Container = styled.div({
  textAlign: 'center',
  margin: '1.85rem 2rem 1.85rem',

  [media.tablet]: {
    marginTop: '60px',
  },
});

const QuestionSequence = styled.div({
  fontFamily: fonts.HKGrotesk.bold,
  display: 'inline-block',
  width: '20px',
  height: '20px',
  fontSize: '0.8rem',
  border: '2px solid #313131',
  borderRadius: '100%',
  lineHeight: '20px',
  margin: '0',
});

const QuestionValue = styled.p({
  fontFamily: fonts.HKGrotesk.bold,
});

const QuestionPage: React.FC<Props> = ({
  sequence,
  value,
  description,
  id,
  questionType,
  isRequired,
  clientAnswer,
  onChange,
  error,
  onNext,
  onBack,
  onNextTouched,
  onSubmit,
}) => (
  <React.Fragment>
    <Container>
      <QuestionSequence>{sequence}</QuestionSequence>
      <QuestionValue>{`${value} ${
        isRequired ? '' : '(Optional)'
      }`}</QuestionValue>
      <QuestionInput
        id={id}
        questionType={questionType}
        description={description}
        clientAnswer={clientAnswer}
        onChange={onChange}
      />
      <ErrorMessage message={onNextTouched ? error : undefined} />
      {onSubmit ? (
        <PrimaryButton onClick={onSubmit} type="submit">
          Submit
        </PrimaryButton>
      ) : (
        <NavButtons onNext={onNext} onBack={onBack} />
      )}
    </Container>
  </React.Fragment>
);

export default QuestionPage;
