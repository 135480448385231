import * as React from 'react';
import { FullPageDisplay, Props as FullPageDisplayProps } from './Base';

type Props = Pick<FullPageDisplayProps, 'callToAction' | 'subMessage'>;

export const ErrorFullPageDisplay: React.FC<Props> = (props) => (
  <FullPageDisplay
    imageSrc={require('../../assets/images/unkown_error-321x220@2x.png')}
    message={`Oops something went wrong.`}
    {...props}
  />
);
