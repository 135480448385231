import * as React from 'react';
import styled from 'styled-components';

import RatingLabels from './RatingLabels';
import withRating from './withRating';

import { QuestionProps } from '../../../pages/QuestionPage';
import { colors, fonts } from '../../../styles';

const Container = styled.div({
  display: 'inline-block',
  marginBottom: '15px'
});

const Square = styled.button<{
  selected: boolean;
}>((props) => ({
  backgroundColor: props.selected ? colors.red.primary : colors.white,
  color: props.selected ? colors.white : colors.black.primary,
  fontFamily: fonts.HKGrotesk.bold,
  fontSize: '1rem',
  border: '1px solid #d1d1d1',
  margin: '0 0 -1px -1px',
  width: '50px',
  height: '50px',
  cursor: 'pointer',

  ':focus': {
    outline: 'none'
  }
}));

type Props = QuestionProps & {
  min?: string;
  max?: string;
  selection: number[];
};

export const RatingNumberInput: React.FC<Props> = ({
  min,
  max,
  selection,
  clientAnswer = [],
  onChange
}) => (
  <Container>
    <RatingLabels min={min} max={max} />
    {selection.map((value) => (
      <Square
        key={value}
        selected={clientAnswer[0] === value}
        onClick={() => onChange([value])}
      >
        {value}
      </Square>
    ))}
  </Container>
);

export default withRating(RatingNumberInput);
