import * as React from 'react';

type Value = string | number;

interface Props {
  title?: Value;
  defaultValue?: Value;
  selection: Value[];
  value?: Value;
  onChange: (value: string) => void;
}

const DropDown: React.FC<Props> = ({
  title,
  defaultValue,
  selection,
  value,
  onChange
}) => (
  <select
    value={value !== undefined ? value : defaultValue}
    onChange={(e) => onChange(e.target.value)}
  >
    {title && (
      <option value={title} disabled={true}>
        {title}
      </option>
    )}
    {selection.map((v) => (
      <option key={v} value={v}>
        {v}
      </option>
    ))}
  </select>
);

export default DropDown;
