import * as React from 'react';
import styled from 'styled-components';

import { BackButton, NextButton } from './Button';

interface Props {
  onNext: () => void;
  onBack: () => void;
}

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  justifyContent: 'center',
  gridColumnGap: '20px',
  margin: '0'
});

const NavButtons: React.FC<Props> = ({ onNext, onBack }) => (
  <Container>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Container>
);

export default NavButtons;
