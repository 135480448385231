const getApiUrl = () => {
  switch (process.env.NODE_ENV) {
    case "staging":
      return "https://api.surveys.staging.zap.com.ph";
    case "preprod":
      return "https://api.surveys.preprod.zap.com.ph";
    case "prod":
      return "https://api.surveys.zap.com.ph";
    case "local":
    default:
      return "http://127.0.0.1:3001";
  }
};

export default getApiUrl;
