import * as React from 'react';

import { SvgProps } from '../../types/props';

export const Smiley3: React.FC<SvgProps> = ({ width, height, color }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    width={width}
    height={height}
    fill={color}
  >
    <g>
      <path
        className="st0"
        d="M50,0C22.43,0,0,22.43,0,50c0,27.57,22.43,50,50,50c27.57,0,50-22.43,50-50C100,22.43,77.57,0,50,0z M50,92.98
        C26.3,92.98,7.02,73.7,7.02,50C7.02,26.3,26.3,7.02,50,7.02C73.7,7.02,92.98,26.3,92.98,50C92.98,73.7,73.7,92.98,
        50,92.98z"
      />
      <circle className="st0" cx="29.06" cy="43.31" r="5.78" />
      <circle className="st0" cx="71.91" cy="43.31" r="5.78" />
      <path
        className="st0"
        d="M62.49,65.01H37.85c-1.94,0-3.51,1.57-3.51,3.51s1.57,3.51,3.51,3.51h24.65c1.94,0,3.51-1.57,3.51-3.51
        S64.43,65.01,62.49,65.01z"
      />
    </g>
  </svg>
);
