import { Either } from 'fp-ts/lib/Either';

export class PromiseE<L, R> {
  private lazyPromise: () => Promise<Either<L, R>>;
  constructor(lP: Promise<Either<L, R>>) {
    this.lazyPromise = () => lP;
  }
  public fold(
    cbLeft: (l: L) => null | void | Promise<any>,
    cbRight: (r: R) => null | void | Promise<any>
  ) {
    return this.lazyPromise().then((res) => {
      return res.fold(cbLeft, cbRight);
    });
  }
}
