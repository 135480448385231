import * as React from 'react';
import styled from 'styled-components';

import Description from './Description';

import { Radio } from '../../../../api/types/monolith/nuloop';
import { QuestionProps } from '../../../pages/QuestionPage';
import { colors, fonts, media } from '../../../styles';

type Props = QuestionProps & {
  questionType: Radio;
};

interface RadioIconProps {
  selected: boolean;
}

const Container = styled.div({
  display: 'grid',
  justifyContent: 'center',
  justifyItems: 'start',

  [media.tablet]: {
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: '40px',
  },
});

const RadioContainer = styled.button({
  backgroundColor: colors.white,
  color: colors.black.primary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  fontFamily: fonts.HKGrotesk.regular,
  fontSize: '1rem',
  cursor: 'pointer',
  marginBottom: '8px',

  ':focus': {
    outline: 'none',
  },
});

const RadioIcon = styled.div<RadioIconProps>((props) => ({
  backgroundColor: props.selected ? colors.red.primary : colors.white,
  display: 'inline-block',
  border: '1px solid #313131',
  borderRadius: '100%',
  width: '18px',
  height: '18px',
  marginRight: '10px',
}));

const RadioInput: React.FC<Props> = ({
  questionType: { selection },
  description,
  clientAnswer = [],
  onChange,
}) => (
  <div>
    <Description value={description} />
    <Container>
      {selection.map((value) => (
        <RadioContainer key={value} onClick={() => onChange([value])}>
          <RadioIcon selected={clientAnswer[0] === value} />
          {value}
        </RadioContainer>
      ))}
    </Container>
  </div>
);

export default RadioInput;
