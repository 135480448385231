const fonts = {
  HKGrotesk: {
    regular: 'hk_groteskregular, Helvetica, Arial, sans-serif',
    medium: 'hk_groteskmedium, Helvetica, Arial, sans-serif',
    bold: 'hk_groteskbold, Helvetica, Arial, sans-serif',
    italic: 'hk_groteskitalic, Helvetica, Arial, sans-serif',
    light: 'hk_grotesklight, Helvetica, Arial, sans-serif',
    lightItalic: 'hk_grotesklight_italic, Helvetica, Arial, sans-serif',
  },
};

export default fonts;
