import * as React from 'react';
import styled from 'styled-components';

import { LeftArrow, RightArrow } from '../Arrows';

import { colors } from '../../styles';

interface Props {
  onClick: () => void;
  color: string;
  direction: 'left' | 'right';
}

interface ButtonProps {
  color: string;
}

const Button = styled.button<ButtonProps>((props) => ({
  borderRadius: '100%',
  width: '50px',
  height: '50px',
  backgroundColor: props.color,
  border: 'none',
  padding: '0',
  cursor: 'pointer',

  ':focus': {
    outline: 'none'
  },
  '@media(min-width: 321px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const NavButton: React.FC<Props> = ({ onClick, color, direction }) => (
  <Button onClick={onClick} color={color}>
    {(() => {
      const commonProps = {
        width: '20px',
        height: '20px',
        color: colors.white
      };
      switch (direction) {
        case 'right':
          return <RightArrow {...commonProps} />;
        case 'left':
          return <LeftArrow {...commonProps} />;
        default:
          return null;
      }
    })()}
  </Button>
);

export default NavButton;
