import * as React from 'react';
import styled from 'styled-components';

import { colors, fonts } from '../styles';
import { ErrorMsg } from '../types/error';

interface Props {
  message: ErrorMsg;
}

const Container = styled.div({
  fontFamily: fonts.HKGrotesk.light,
  fontSize: '0.8rem',
  color: colors.red.primary,
  margin: '1rem 0'
});

const Span = styled.span({
  display: 'inline-block',
  minHeight: '0.8rem'
});

const ErrorMessage: React.FC<Props> = ({ message }) => (
  <Container>
    <Span>{message}</Span>
  </Container>
);

export default ErrorMessage;
