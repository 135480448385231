import * as React from "react";
import styled from "styled-components";

import Description from "./Description";

import { QuestionProps } from "../../../pages/QuestionPage";
import { colors, fonts } from "../../../styles";

const Input = styled.input({
  fontFamily: fonts.HKGrotesk.light,
  fontSize: "1.4rem",
  border: "none",
  textAlign: "center",
  caretColor: colors.red.primary,

  ":focus": {
    outline: "none",
  },
  "::placeholder": {
    color: colors.black.muted,
  },
});

const TextFieldInput: React.FC<QuestionProps> = ({
  description,
  clientAnswer = [],
  onChange,
}) => (
  <div>
    <Description value={description} />
    <Input
      autoFocus={true}
      type="text"
      placeholder="Type here..."
      value={clientAnswer[0] || ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange([e.target.value])
      }
    />
  </div>
);

export default TextFieldInput;
