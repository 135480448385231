import * as React from 'react';
import styled from 'styled-components';
// import { PrimaryButton } from '../components/Button/PrimaryButton';
import { PrimaryButton } from '../components/Button/PrimaryButton';
// import { DownloadLinks } from '../components/DownloadLinks';
import { ErrorFullPageDisplay } from '../components/FullPageDisplay';
import { colors, fonts } from '../styles';
import { AppState } from '../types/state';

type Props = Pick<AppState, 'submitResponse'> & { resubmit: () => void };

const RewardDog = styled.img({
  height: '200px'
});

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '4rem',
  textAlign: 'center'
});

export const Success: React.FC<{ message?: React.ReactNode }> = ({
  message
}) => (
  <Container>
    <RewardDog src={require('../assets/images/rewarded_dog-156x200@2x.png')} />
    {message}
  </Container>
);

const ThankYouMsg = styled.p<{ big?: boolean }>(({ big }) => ({
  fontSize: big ? '1.3rem' : 'initial',
  fontFamily: big ? fonts.HKGrotesk.bold : fonts.HKGrotesk.regular,
  marginTop: '1rem'
}));
const RewardMsg = styled.p({
  fontFamily: fonts.HKGrotesk.regular,
  marginTop: 0,
  textAlign: 'center',
  '& > strong': {
    fontSize: '1.5rem',
    color: colors.red.primary,
    fontFamily: fonts.HKGrotesk.bold,
    textTransform: 'uppercase'
  }
});

const RewardMessage: React.FC<{ message?: string }> = ({ message }) => (
  <React.Fragment>
    <ThankYouMsg>Thank you for your feedback</ThankYouMsg>
    <RewardMsg>
      You've received
      {message ? (
        <strong>
          <br />
          {message}
        </strong>
      ) : (
        'a reward'
      )}
    </RewardMsg>
  </React.Fragment>
);

export const Finish: React.FC<Props> = ({ submitResponse, resubmit }) => {
  const errorProps = {
    callToAction: (
      <PrimaryButton onClick={resubmit} type="button">
        Resubmit
      </PrimaryButton>
    ),
    subMessage: 'We are unable to submit your response. Please try again.'
  };
  if (submitResponse.error) {
    switch (submitResponse.error.type) {
      case 'DisjunctionError':
        switch (submitResponse.error.error.type) {
          default:
            return <ErrorFullPageDisplay {...errorProps} />;
        }
      default:
        return <ErrorFullPageDisplay {...errorProps} />;
    }
  }
  if (submitResponse.success) {
    if (submitResponse.success.reward) {
      switch (submitResponse.success.reward.type) {
        case 'Points': {
          const { amount, currency } = { ...submitResponse.success.reward };
          return (
            <Success
              message={
                <RewardMessage
                  message={`${amount} ${currency ? currency.name : 'Points'}`}
                />
              }
            />
          );
        }
        case 'Coupon': {
          const { promotion = {} } = { ...submitResponse.success.reward };
          const { name } = promotion;
          return <Success message={<RewardMessage message={name} />} />;
        }
        case 'NoReward':
        default:
          return (
            <Success
              message={
                <ThankYouMsg big={true}>
                  Thank you for your feedback!
                </ThankYouMsg>
              }
            />
          );
      }
    }
    return (
      <Success
        message={
          <ThankYouMsg big={true}>Thank you for your feedback!</ThankYouMsg>
        }
      />
    );
  }
  return null;
};
