import * as React from 'react';
import styled from 'styled-components';

import {
  RatingNumber,
  RatingSmiley,
  RatingStar,
} from '../../../../api/types/monolith/nuloop';
import { QuestionProps } from '../../../pages/QuestionPage';
import Description from './Description';

export interface Props {
  questionType: RatingNumber | RatingStar | RatingSmiley;
}

interface WrappedComponentProps extends QuestionProps {
  min?: string;
  max?: string;
  selection: number[];
}

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridRowGap: '15px',
  justifyContent: 'center',

  '@media(min-width: 575px)': {
    gridTemplateColumns: 'auto auto',
  },
});

const withRating = (WrappedComponent: React.FC<WrappedComponentProps>) => {
  const WithRating: React.FC<Props & QuestionProps> = ({
    questionType: { selection, labels: { min = '', max = '' } = {} },
    description,
    clientAnswer = [],
    onChange,
  }) => (
    <div>
      <Description value={description} />
      {selection.length > 5 ? (
        <Container>
          <WrappedComponent
            min={min}
            selection={selection.slice(0, 5)}
            clientAnswer={clientAnswer}
            onChange={onChange}
          />
          <WrappedComponent
            max={max}
            selection={selection.slice(5, 10)}
            clientAnswer={clientAnswer}
            onChange={onChange}
          />
        </Container>
      ) : (
        <WrappedComponent
          min={min}
          max={max}
          selection={selection}
          clientAnswer={clientAnswer}
          onChange={onChange}
        />
      )}
    </div>
  );

  return WithRating;
};

export default withRating;
