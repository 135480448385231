import * as React from 'react';
import styled from 'styled-components';
import { Spinner } from '../components/Spinner';

const Container = styled.div({
  position: 'fixed',
  top: '50%',
  marginTop: '-40px',
  width: '100%'
});

export const PageSpinner: React.FC<{}> = () => (
  <Container>
    <Spinner />
  </Container>
);
