import * as React from 'react';
import styled from 'styled-components';
import { Spinner } from './Spinner';

export const Container = styled.div({
  opacity: 0.5,
  background: '#000',
  width: '100%',
  height: '100%',
  zIndex: 999,
  top: 0,
  left: 0,
  position: 'fixed',
  display: 'flex',
  alignItems: 'center'
});

export const Overlay: React.FC = () => (
  <Container>
    <Spinner />
  </Container>
);
