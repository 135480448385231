import * as React from 'react';
import styled from 'styled-components';
import { colors } from '../styles';

const Container = styled.div({
  margin: '20px 0',
  position: 'relative',
  width: '100%',
  height: '40px',
  animation: 'rotate 2s linear infinite'
});

const SvgSpin = styled.svg({
  width: 40,
  height: 40,
  transition: 'auto',
  position: 'absolute',
  marginLeft: '-20px',
  left: '50%',
  fill: 'transparent',
  animation: 'dash 2s ease infinite',
  stroke: colors.red.primary,
  strokeWidth: 3,
  '& *': {
    transition: 'none'
  }
});

export const Spinner: React.FC = () => (
  <Container>
    <SvgSpin>
      <circle cx="20" cy="20" r="15" />
    </SvgSpin>
  </Container>
);
