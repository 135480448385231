import * as React from 'react';
import styled from 'styled-components';

import { colors, fonts, media } from '../../../styles';

interface Props {
  value?: string;
  extra?: string;
}

const Container = styled.div({
  marginBottom: '30px',
  fontSize: '0.8rem',

  [media.tablet]: {
    marginBottom: '60px'
  }
});

const Text = styled.span({
  fontFamily: fonts.HKGrotesk.light
});

const Extra = styled.span({
  fontFamily: fonts.HKGrotesk.lightItalic,
  color: colors.black.muted
});

const Description: React.FC<Props> = ({ value, extra }) => (
  <Container>
    {value && <Text>{`${value} `}</Text>}
    {extra && <Extra>{`(${extra})`}</Extra>}
  </Container>
);

export default Description;
